@font-face {
  font-family: "PetraScriptEF";
  src: local("PetraScriptEF"), url(./fonts/Petra\ Script\ EF\ Regular.ttf) format('truetype');
}

@font-face {
  font-family: "Autograf";
  src: local("Autograf"), url(./fonts/AutografPersonalUseOnly-mOBm.ttf) format('truetype');
}

@font-face {
  font-family: "Antically";
  src: local("Antically"), url(./fonts/AnticallyRegular-OVox8.ttf) format('truetype');
}

@font-face {
  font-family: "JustSignature";
  src: local("JustSignature"), url(./fonts/JustSignature-92w7.ttf) format('truetype');
}

@font-face {
  font-family: "Adinda Melia";
  src: local("Adinda Melia"), url(./fonts/Adinda\ Melia.ttf) format('truetype');
}

@font-face {
  font-family: "Andina";
  src: local("Andina"), url(./fonts/Andina\ Demo.ttf) format('truetype');
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.upload {
  --ease-in-out-quartic: cubic-bezier(0.645, 0.045, 0.355, 1);
  position: relative;
  display: inline-flex;
  background: transparent;
  border-radius: 0px;
  overflow: hidden;
  transform: rotate(0);
}

.upload-filename {
  padding-left: 8px;
  transition: opacity 300ms ease;
}
.upload-filename.inactive {
  opacity: 0.6;
}
.upload-info {
  display: flex;
  align-items: center;
  padding: 18px;
  margin-right: 40px;
  color: #000;
}
.upload-button-text {
  padding: 8px 16px;
  color: white;
  background-color: #5f616499;
  border-radius: inherit;
  outline: none;
  cursor: pointer;
  transition: background-color 200ms ease, box-shadow 300ms ease;
  
}
.upload-button-text:hover, .upload-button-text:focus {
  background-color: #5f616499;
}
.upload-button-text.inactive {
  background-color: rgba(255, 255, 255, 0.38);
  cursor: not-allowed;
}

.browse-btn {
  padding: 6px 12px;
  color: white;
  background-color: #0178f2;
  border-radius: inherit;
  outline: none;
  cursor: pointer;
  transition: background-color 200ms ease, box-shadow 300ms ease;
}

.browse-btn:hover, .browse-btn:focus {
  background-color: #0178f2;
}

.browse-btn.inactive {
  background-color: #0178f2;
  cursor: not-allowed;
}

[type=file] {
  height: 0;
  overflow: hidden;
  width: 0;
  cursor: pointer;
}


.pdf_page_custom{
  position: 'relative';
  /* top: 0;
  left:0; */
  width:'100%';
  height:'100%';
  /* border: 10px solid red; */
}

.react-resizable {
  position: relative;
}
.react-resizable-handle {
  position: absolute;
  width: 15px;
  height: 15px;
  bottom: 0;
  right: 0;
  /* z-index: 99; */
  /* background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAAAGAAAABgAPBrQs8AAAAHdElNRQfmCwIMNi/6x/7tAAAAYUlEQVQoz5WRyw2AMAxDnzoCEpXYfyN6YIl2hnAonyDqIJLri5040LCrK69KTHxUn91YIqBgFLIGZlatYhhESD12z7ERscoP5GnURtF5lX5AgAjgNpLAqSIA/19Lw2wdvANHn0NQzRpaRQAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMi0xMS0wMlQxMjo1NDo0NyswMDowMNPrWX4AAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjItMTEtMDJUMTI6NTQ6NDcrMDA6MDCituHCAAAAKHRFWHRkYXRlOnRpbWVzdGFtcAAyMDIyLTExLTAyVDEyOjU0OjQ3KzAwOjAw9aPAHQAAAABJRU5ErkJggg=="); */
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+");
  background-position: bottom right;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
  /* border-right: 2px solid red;
  border-bottom: 2px solid red; */
  /* border-radius: 50%; */
  /* box-shadow: 0 2px 5px rgb(0 0 0 / 25%); */
  /* background: red; */
}
#content {
  width: 100%;
  background: transparent;
  padding-bottom: 200px;
}
.layoutRoot {
  display: flex;
  flex-wrap: wrap;
}
.box {
  display: inline-block;
  background: rgba(71, 185, 114, 0.1);
  border: 1px solid #0178f2;
  border-radius: 5px;
  text-align: center;
  padding: 0px;
  box-sizing: border-box;
  /* margin-bottom: 10px; */
  overflow: hidden;
  position: relative;
  /* margin: 20px; */
}
.box .text {
  /* text-align: center; */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 36px;
}
.box3 .react-resizable-handle {
  display: none;
}
/* .box3:hover .react-resizable-handle {
  display: block;
} */

.MuiPagination-root .MuiPagination-ul {
  flex-wrap: nowrap;
}
.MuiPagination-root .MuiPagination-ul li:first-child {
  flex-basis: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.MuiPagination-root .MuiPagination-ul li:first-child > button::after {
  margin-left: 10px;
  content: 'previous';
}
.MuiPagination-root .MuiPagination-ul li:last-child {
  flex-basis: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.MuiPagination-root .MuiPagination-ul li:last-child > button::before {
  margin-right: 10px;
  content: 'next';
}



/*Checkboxes styles*/
/* Custom checkbox styles */
/* .MuiCheckbox-root {
  display: none;
}

.MuiCheckbox-root {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 20px;
  font: 14px/20px 'Open Sans', Arial, sans-serif;
  color: #ddd;
  cursor: pointer;
  user-select: none;
}

.MuiCheckbox-root:last-child {
  margin-bottom: 0;
}

.MuiCheckbox-root:before {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  border: 1px solid #6cc0e5;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.6;
  transition: all 0.12s, border-color 0.08s;
}

.Mui-checked:before {
  width: 10px;
  top: -5px;
  left: 5px;
  border-radius: 0;
  opacity: 1;
  border-top-color: transparent;
  border-left-color: transparent;
  transform: rotate(45deg);
} */


